.content {
    padding-right: 300px;
    text-align: justify;
    font-family: 'Courgette', cursive;
    height: 100%;
}

.box {
    width: 80%;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;
}

.homeimg {
    width: 80%;
}

h1 {
    font-size: 50px;
    text-align: center;
    border-bottom: 4px solid #b91d47;
    padding-bottom: 10px;
    color: #b91d47;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

p {
    font-size: 25px;
    margin-top: 30px;
}

p:first-letter {
    font-size: 150%;
    color: #b91d47;
}

.logoContent {
    width: 30%;
}

.headerContent {
    text-align: center;
    margin-bottom: 40px;
}

.homeImgContainer {
    width: 45%;
    float: left;
}

.homeBox {
    width: 88% !important;
}

.homeContentContainer {
    width: 55%;
    float: left;
}

.homeImg {
    width: 100%;
    border-radius: 40px;
}

.homeContent {
    margin-left: 40px;
    margin-top: 20px;
    text-align: left;
    font-size: 2.5vw;
}

.homeContent h5 {
    margin-top: 15px;
    color: white;
}


.home-background-image {
    background-image: url('../img/backgroundimage.webp');
    height: 100%;
    background-repeat: no-repeat;

    background-attachment: fixed;
    background-size: cover;

    height: 100%;

}

.mask-custom {
    /* fallback for old browsers */
    background: #667eea;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(102, 126, 234, 0.55), rgba(118, 75, 162, 0.55));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(102, 126, 234, 0.55), rgba(118, 75, 162, 0.55));
    height: 100%;
}

.content li {
    margin-left: 60px;
    margin-top: 15px;
    font-size: 18px;
    text-align: left;
}

.content li:first-letter {
    font-size: 120%;
    color: #b91d47;
}

.subtitle {
    text-align: center;
    font-size: 27px;
    margin-top: 20px;
}

.contentTable {
    font-size: 23px;
    margin-top: 30px;
    border-collapse: separate;
    border-spacing: 0 25px;
}

.contentTable td {
    padding-right: 30px;
}

.bigger {
    font-size: 30px;
    font-weight: bold;
}

.row{
    overflow: hidden;
    margin-top: 35px;
    margin-bottom: 30px;
}

.citationAndAuthor {
    width: 435px;
    float: right;
}

.citation {
    font-size: 18px;
}

.author {
    text-align: right;
    font-size: 18px;
    margin-top: 10px;

}

.author:first-letter{
    font-size: 18px;
    color: black;
}

.acupuncture-title{
    font-size: 50px;
    text-align: center;
    border-bottom: none;
    color: #b91d47;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.acupuncture-subtitle{
    font-size: 25px;
    text-align: center;
    border-bottom: 4px solid #b91d47;
    padding-bottom: 10px;
    color: #b91d47;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: 0px;
}

.acupuncture-subtitle:first-letter{
    font-size: 25px;
    color: #b91d47;
}

@media all and (max-width: 1024px) {
    .content {
        padding-top: 70px;
        padding-right: 0px;
    }

    .homeImgContainer {
        width: 70%;
        margin: auto;
        float: none;
    }

    .homeBox {
        width: 88% !important;
    }

    .homeContentContainer {
        width: 90%;
        float: none;
        margin: auto;
    }

    .homeContent {
        font-size: 5vw;
        text-align: center;
        margin-left: 0;
    }
    .homeContent h5{
        color: black;
    }

    .logoContent {
        width: 70%;
    }

    .home-background-image {
        background-image: none;
    }

    .mask-custom {
        background: none;
    }

    .box {
        padding-top: 30px;
    }
    .citationAndAuthor {
        width: 80%;
    }
    h1{
        font-size: 30px;
    }
}

