@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

* {
    padding: 0px;
    margin: 0px;
}

a {
    all: unset;
}

body {

    background-color: #fde0e6;
    height: 100%;
}

html {
    overflow-y: scroll;

}

html,
body,
.App,
#root {
    height: 100%;

}