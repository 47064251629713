.navigation-header {
    padding: 50px 10px 30px 10px;
}

.navigation-logo {
    width: 20%;
}

.navigation-title {
    font-family: 'Courgette', cursive;
    font-size: 30px;
    text-align: center;
    width: 80%;
    float: right;
    color: #ba0505;
}

.navigation-bar a {
    text-decoration: none;
    color: black;
}

.navigation-bar {
    background-color: #FFC0CB;
    position: fixed;
    width: 300px;
    top: 0;
    right: 0;
    height: 100%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navivation-header-list-of-elements {
    text-align: left;
    list-style: none;
    font-size: 20px;
    letter-spacing: 0.5px;
}

.navigation-header-element {
    padding: 20px 0px 20px 20px;
    padding-left: 30px;
}

.navigation-header-element:hover {
    transition: all 0.5s ease 0s;
    background-color: #DFA8B2;
}

.navigation-footer-list-of-elements {
    position: absolute;
    bottom: 40px;
    width: 100%;
    list-style: none;
    text-align: center;
}

.navigation-footer-element {
    width: 100%;
    height: 50px;
    display: inline;
}

.navigation-dropdown-element {
    padding-top: 10px;
    padding-bottom: 10px;
    list-style: none;
    text-align: left;
    padding-left: 50px;
    font-size: 15px;
    transition: padding 1s;

}

.navigation-dropdown-element:hover {
    transition: padding 1s;
    padding-left: 70px;
}

.slider {
    display: none;
}

.navigation-header-dropdown-icon {
    margin-left: 10px;
}

//COLLAPSE

.navigation-collapse {
    display: none;
    background-color: #DFA8B2;
    height: 70px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.navigation-collapse-button-icon {
    padding: 5px 10px 8px 10px;
    font-size: 20px;
    width: 35px;
    border: 1px solid #e5e5e5;
    border-radius: .25rem;
    color: #7f7f7f;
    background-color: #ffffff;
    margin-top: 15px;
    margin-left: 15px;
}

@media all and (max-width: 1024px) {
    .navigation-bar {
        display: none;
    }

    .navigation-collapse {
        display: block;
        position: fixed;
        width: 100%;
    }

    .navigation-header-element {
        text-align: center;
        font-size: 18px;
        padding: 10px;
    }

    .navigation-dropdown-element {
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 20px;
    }

    .navigation-header-element-link {
        display: inline-block;
        padding: 8px;
        border: 4px solid #231f20 !important;
        border-radius: 25px;
        background-color: #b86279;
        width: 50%;
        margin: 10px;
        font-size: 16px;
    }

    .navigation-dropdown-element:hover{
        transition: none;
        padding: 0px;
    }
}

.navigation-collapse-content {
    display: none;
    background-color: #DFA8B2;
    height: auto;
    padding-bottom: 20px;
    position: relative;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.navigation-collapse-item {
    display: inline-block;
    width: 80%;
    padding: 8px;
    border: 4px solid #231f20 !important;
    border-radius: 50px;
    background-color: #b91d47;
}
